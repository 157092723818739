import './bootstrap';
import {tns} from "tiny-slider/src/tiny-slider";

jQuery('[data-toggle="tooltip"]').tooltip();

jQuery(document).on('click', '[data-scroll-to]', function (e) {
    e.preventDefault();

    jQuery("html, body").animate({scrollTop: jQuery(jQuery(this).data('scroll-to')).offset().top}, 100);
});

jQuery(document).ready(function () {
    if (window.location.hash.length) {
        console.log(window.location.hash);
        jQuery("html, body").animate({scrollTop: jQuery(window.location.hash.replace('#', '.')).offset().top}, 100);
    }
    let slider = tns({
        container: '.server-slider',
        items: 1,
        slideBy: 'page',
        autoplay: false,
        nav: false,
        loop: false,
        rewind: true,
        gutter: 30,
        controlsText: ['<', '>'],
        responsive: {
            700: {
                items: 2
            },
            991: {
                items: 3
            },
        }
    });
});
